$(document).ready(function() {
	if ( $(window).width() > 769) {
		$('.custom__dropdown-ul').on('mouseover', function() {
			$('.custom__dropdown-container').slideDown();
		})

		$('.custom__dropdown-container').on('mouseleave', function() {
			$('.custom__dropdown-container').slideUp();
		})
	}
})